import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"
import { MemoryContainer, MemoryWrapper } from "../style/styled"

export default function MemoryPath1({ data }) {
  const allFiction = data.allContentfulTheMemoryPathPart1.edges

  return (
    <Layout>
      <SEO title="The Memory Path Part 1" />
      <ContentContainer>
        <h1>The Memory Path Part 1</h1>
        <MemoryWrapper>
          {allFiction?.map(({ node }) => {
            const title = node.title
            const subtitle = node.subtitle
            return (
              <Link
                key={node.slug}
                to={`/${node.slug}`}
                style={{ boxShadow: `none` }}
              >
                <MemoryContainer>
                  <h3>{title}</h3>
                  <p>{subtitle}</p>
                </MemoryContainer>
              </Link>
            )
          })}
        </MemoryWrapper>
      </ContentContainer>
    </Layout>
  )
}

export const memoryPathPartOnePageQuery = graphql`
  query {
    allContentfulTheMemoryPathPart1(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          slug
          content {
            json
          }
          title
          subtitle
        }
      }
    }
  }
`
